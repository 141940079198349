'use client'

import { ReactNode } from 'react'
import { Cell, Text, List } from '@vinted/web-ui'
import { useIntl } from 'react-intl'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { formatCurrencyAmount } from '_libs/utils/formatString'
import {
  EscrowFeeBuyerProtectionModel,
  EscrowFeeItemVerificationModel,
} from 'types/models/escrow-fees'

type Props = {
  feeTitle: string
  prefix: ReactNode
  infoIcon: ReactNode
  escrowFee: EscrowFeeBuyerProtectionModel | EscrowFeeItemVerificationModel
  isItemOwner: boolean
  discountNote?: string | null
  testId: string
  noteText?: string
}

const FeeDetails = ({
  feeTitle,
  prefix,
  infoIcon,
  escrowFee,
  isItemOwner,
  discountNote,
  testId,
  noteText,
}: Props) => {
  const translate = useTranslate('item_price_breakdown_detailed')
  const { formatDate, locale } = useIntl()

  if (!escrowFee) return null

  const {
    discountPercentage,
    isFree,
    finalPrice,
    originalPrice,
    discountStartDate,
    discountEndDate,
    lowestPrice30Days,
  } = escrowFee

  const hasDiscount = !!discountPercentage
  const shouldShowDiscount = !isItemOwner && hasDiscount

  const renderDiscountDates = () =>
    shouldShowDiscount && (
      <>
        {discountNote && (
          <List.Item>
            <span data-testid="discount-note">
              <Text as="h3" theme="muted" type={Text.Type.Subtitle} text={discountNote} />
            </span>
          </List.Item>
        )}
        {discountStartDate && discountEndDate && (
          <List.Item>
            <Text
              as="h3"
              theme="muted"
              type={Text.Type.Subtitle}
              text={translate('promotion_period', {
                dateFrom: formatDate(discountStartDate, {
                  day: 'numeric',
                  month: 'short',
                }),
                dateTo: formatDate(discountEndDate, {
                  day: 'numeric',
                  month: 'short',
                }),
              })}
            />
          </List.Item>
        )}
        {lowestPrice30Days && (
          <List.Item>
            <Text
              as="h3"
              theme="muted"
              type={Text.Type.Subtitle}
              text={translate('lowest_price_in_30_days_header', {
                amount: formatCurrencyAmount(lowestPrice30Days, locale),
              })}
            />
          </List.Item>
        )}
      </>
    )

  const renderTitle = () => {
    const discountText = isFree ? `${translate('is_free')}` : `(-${discountPercentage as number}%)`

    return (
      <div className="u-flexbox u-align-items-center u-gap-small">
        {feeTitle}
        {shouldShowDiscount && <Text as="span" theme="warning" text={discountText} />}
        {infoIcon}
      </div>
    )
  }

  const renderDiscountAmount = () => (
    <div className="u-flexbox u-gap-small">
      <Text
        as="h2"
        text={formatCurrencyAmount(finalPrice, locale)}
        type={Text.Type.Title}
        theme="amplified"
      />
      {shouldShowDiscount && (
        <div className="u-padding-right-small">
          <Text
            as="h3"
            theme="muted"
            type={Text.Type.Subtitle}
            strikethrough
            text={formatCurrencyAmount(originalPrice, locale)}
          />
        </div>
      )}
    </div>
  )

  const renderNote = () =>
    noteText && (
      <List.Item>
        <div className="u-padding-bottom-small">
          <Text as="h3" text={noteText} type={Text.Type.Subtitle} theme="muted" />
        </div>
      </List.Item>
    )

  return (
    <Cell
      testId={testId}
      styling={Cell.Styling.Narrow}
      title={renderTitle()}
      prefix={prefix}
      body={
        <>
          {renderDiscountAmount()}
          {(shouldShowDiscount || noteText) && (
            <List size={List.Size.Tight}>
              {renderNote()}
              {renderDiscountDates()}
            </List>
          )}
        </>
      }
    />
  )
}

export default FeeDetails
