'use client'

import { useEffect } from 'react'
import { BottomSheet, Button, Cell, Container, Dialog, Divider, Text } from '@vinted/web-ui'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { ContentLoader, ErrorState } from '@marketplace-web/shared/ui-helpers'
import useFetch from 'hooks/useFetch'
import { EscrowFeesModel } from 'types/models/escrow-fees'
import { getTransactionEscrowFees } from 'data/api'
import { transformItemEscrowFeesResponse } from 'data/api/transformers/response'

import ItemDetails from './ItemDetails'
import ShippingDetails from './ShippingDetails'
import BuyerProtectionFee from './BuyerProtectionFee'
import ItemVerificationFee from './ItemVerificationFee'
import ElectronicsVerificationFee from './ElectronicsVerificationFee'
import LegalNote from './LegalNote'
import ModalNavigation from './ModalNavigation'

type Props = {
  isVisible: boolean
  onClose: () => void
  itemPhotoSrc?: string | null
  itemPrice: string
  itemTitle: string
  escrowFees?: EscrowFeesModel
  isItemOwner: boolean
  isSellerBusiness: boolean
  transactionId?: number
  itemId: number
}

const DetailedItemPriceBreakdownModal = ({
  isVisible,
  onClose,
  itemPrice,
  itemTitle,
  itemPhotoSrc,
  escrowFees: providedEscrowFees,
  isSellerBusiness,
  isItemOwner,
  transactionId,
  itemId,
}: Props) => {
  const breakpoint = useBreakpoint()
  const translate = useTranslate('item_price_breakdown_detailed')

  const {
    transformedData: transactionEscrowFees,
    fetch: fetchTransactionEscrowFees,
    isLoading: isTransactionEscrowFeesLoading,
  } = useFetch(getTransactionEscrowFees, transformItemEscrowFeesResponse)

  useEffect(() => {
    if (providedEscrowFees) return

    if (transactionId && isVisible) {
      fetchTransactionEscrowFees({ transactionId })
    }
  }, [providedEscrowFees, transactionId, isVisible, fetchTransactionEscrowFees])

  const escrowFees = providedEscrowFees || transactionEscrowFees

  const renderContent = () => {
    if (isTransactionEscrowFeesLoading) return <ContentLoader />
    if (!escrowFees) return <ErrorState />

    return (
      <>
        <Container styling={Container.Styling.Narrow}>
          <ItemDetails itemPrice={itemPrice} itemTitle={itemTitle} itemPhotoSrc={itemPhotoSrc} />
          <BuyerProtectionFee
            buyerProtection={escrowFees.buyerProtection}
            isSellerBusiness={isSellerBusiness}
            isItemOwner={isItemOwner}
            itemId={itemId}
          />
        </Container>
        <Divider />
        <Container styling={Container.Styling.Narrow}>
          <Cell styling={Cell.Styling.Narrow}>
            <Text
              text={translate('selected_at_checkout_title')}
              type={Text.Type.Subtitle}
              as="label"
            />
          </Cell>
          <ItemVerificationFee
            itemVerification={escrowFees.itemVerification}
            isItemOwner={isItemOwner}
            itemId={itemId}
          />
          <ElectronicsVerificationFee
            electronicsVerificationFee={escrowFees.electronicsVerification}
            isItemOwner={isItemOwner}
            itemId={itemId}
          />
          <ShippingDetails shippingFee={escrowFees.shipping} isItemOwner={isItemOwner} />
        </Container>
        <LegalNote isSellerBusiness={isSellerBusiness} />
      </>
    )
  }

  if (breakpoint.tabletsUp)
    return (
      <Dialog testId="detailed-item-price-breakdown" show={isVisible}>
        <ModalNavigation onClose={onClose} />
        {renderContent()}
        <Container styling={Container.Styling.Narrow}>
          <Cell styling={Cell.Styling.Narrow}>
            <Button
              text={translate('actions.close')}
              styling={Button.Styling.Filled}
              onClick={onClose}
              testId="item-price-breakdown-close-button"
            />
          </Cell>
        </Container>
      </Dialog>
    )

  return (
    <BottomSheet testId="detailed-item-price-breakdown" onClose={onClose} isVisible={isVisible}>
      <ModalNavigation onClose={onClose} />
      {renderContent()}
    </BottomSheet>
  )
}

export default DetailedItemPriceBreakdownModal
